import { Template, ThemeName, Language } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const tampereenEnergia: Account = {
  name: 'Tampereen Energia (entinen sähkölaitos)',
  emailDomain: 'tampereenenergia.fi',
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.Summary, Template.EnergyAnalysis],
  allowedThemes: [ThemeName.TampereenSahkolaitos],
  reportLanguages: [Language.Fi],
}

export default tampereenEnergia
