import { Box, MenuItem, Typography, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { every, isNil, values as lodashValues, map, pick, pickBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getAccountDefaultValuesForForm } from '../accounts'
import { BuildingDataModel, PdfApiInput } from '../api-types'
import { CustomCheckboxField, CustomSelectField, CustomTextField } from '../fields'
import ActionButtons from './ActionButtons'
import AutoUpdateEnergyConsumption from './AutoUpdateEnergyConsumption'
import BuildingType from './BuildingType'
import ConstructionDecade from './ConstructionDecade'
import FloorArea from './FloorArea'
import HeatConsumption from './HeatConsumption'
import HeatRecovery from './HeatRecovery'
import HeatingDistriButionMethod from './HeatingDistributionMethod'
import HeatingFuel from './HeatingFuel'
import OtherParameters from './OtherParameters'
import ReportTypeField from './ReportTypeField'
import Solar from './Solar'
import UpdateParamaetersLoader from './UpdateParamaetersLoader'
import getDefaultValues from './defaultValues'
import downloadPdf from './downloadPdf'

type Props = {
  buildingDetailsFromSearch: BuildingDataModel | undefined
  email: string
}
export default function CreateReportForm({ buildingDetailsFromSearch, email }: Props) {
  const { t } = useTranslation()
  const [isLoadingEnergyConsumption, setIsLoadingEnergyConsumption] = React.useState(false)

  const {
    defaultLanguage,
    defaultTheme,
    defaultTemplate,
    showSolar,
    languages,
    themes,
    templates,
  } = getAccountDefaultValuesForForm(email)
  const validationSchema = Yup.mixed<PdfApiInput>().default(
    getDefaultValues(defaultTheme, defaultTemplate, defaultLanguage, showSolar)
  )
  const defaultValues = validationSchema.getDefault()

  const classes = useStyles()
  const { initialValues, buildingDataFound } = prepareInitialValues(
    defaultValues,
    buildingDetailsFromSearch,
    email
  )

  return (
    <Box flexGrow={1}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) =>
          downloadPdf(values, helpers.setSubmitting, t('reportNameTemplate'))
        }
        enableReinitialize
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form className={classes.formContainer}>
            <AutoUpdateEnergyConsumption setLoadingStatus={setIsLoadingEnergyConsumption} />
            {!buildingDataFound && (
              <Typography variant="caption" color="error">
                {t('buildings.confirmDefaultValues')}
              </Typography>
            )}
            <BuildingType disabled={isLoadingEnergyConsumption} />
            <ConstructionDecade disabled={isLoadingEnergyConsumption} />
            <HeatingFuel disabled={isLoadingEnergyConsumption} />
            <HeatingDistriButionMethod disabled={isLoadingEnergyConsumption} />
            <HeatRecovery disabled={isSubmitting || isLoadingEnergyConsumption} mb={2} />

            <FloorArea disabled={isLoadingEnergyConsumption} buildingType={values.building_type} />
            <HeatConsumption disabled={isLoadingEnergyConsumption} />
            {values.hotwater_consumption_mwh && (
              <Field
                name="hotwater_consumption_mwh"
                label={t('fields.hotwaterConsumption')}
                component={CustomTextField}
                disabled={isLoadingEnergyConsumption}
              />
            )}
            {values.elec_consumption_mwh && (
              <Field
                name="elec_consumption_mwh"
                label={t('fields.elecConsumption')}
                component={CustomTextField}
                disabled={isLoadingEnergyConsumption}
              />
            )}
            <ReportTypeField disabled={isLoadingEnergyConsumption} />
            <Field
              name="vat_percentage"
              label={t('fields.vat_percentage')}
              component={CustomSelectField}
              disabled={isLoadingEnergyConsumption}
            >
              {map(VAT, (value, key) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Field>
            {showSolar && (
              <Field
                name="calculate_energy_efficiency"
                component={CustomCheckboxField}
                label={t('fields.calculateEnergyEfficiency')}
                disabled={isLoadingEnergyConsumption}
              />
            )}
            {showSolar && <Solar disabled={isLoadingEnergyConsumption} />}
            <OtherParameters disabled={isSubmitting || isLoadingEnergyConsumption} />
            <ActionButtons
              disabled={isSubmitting || isLoadingEnergyConsumption || !values.heat_consumption_mwh}
              onPrimaryButtonClick={submitForm}
              isSubmitting={isSubmitting}
              primaryLabel={t('buttons.downloadPdf')}
              themes={themes}
              templates={templates}
              languages={languages}
            />
            <UpdateParamaetersLoader enabled={isLoadingEnergyConsumption} />
          </Form>
        )}
      </Formik>
    </Box>
  )
}

type PreparedInitialValues = {
  initialValues: PdfApiInput
  buildingDataFound: boolean
}
const prepareInitialValues = (
  defaultValues: PdfApiInput,
  buildingDetailsFromSearch: BuildingDataModel | undefined,
  email: string
): PreparedInitialValues => {
  const name = buildingDetailsFromSearch?.street_address || ''
  const constructionDecade = calculateDecade(buildingDetailsFromSearch?.construction_year)
  const buildingDetails = {
    ...buildingDetailsFromSearch,
    name,
    construction_decade: constructionDecade,
  }
  const finiteInitialValues = pickBy(buildingDetails, (value) => !isNil(value))
  const initialValues = {
    ...defaultValues,
    ...finiteInitialValues,
    user_details: { email },
  }
  const buildingDataFound = every(
    lodashValues(
      pick(buildingDetailsFromSearch, ['building_type', 'heating_fuel', 'construction_year'])
    ),
    (value) => !isNil(value)
  )
  return {
    initialValues,
    buildingDataFound,
  }
}

const calculateDecade = (year: number | null | undefined) => {
  if (!year) return undefined
  if (year < 1940) return 1930
  if (year < 1950) return 1940
  if (year < 1960) return 1950
  if (year < 1970) return 1960
  if (year < 1980) return 1970
  if (year < 1990) return 1980
  if (year < 2000) return 1990
  if (year < 2010) return 2000
  return 2010
}

const VAT: Record<string, number> = {
  '24 %': 0.24,
  '0 %': 0,
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(4),
    },
    justifyContent: 'space-between',
  },
  textField: {
    flexGrow: 1,
    marginRight: '4%',
  },
}))
