import { Template, ThemeName, Language } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const tampereenSahkolaitos: Account = {
  name: 'Tampereen Sähkölaitos',
  emailDomain: 'sahkolaitos.fi',
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.Summary, Template.EnergyAnalysis],
  allowedThemes: [ThemeName.TampereenSahkolaitos],
  reportLanguages: [Language.Fi],
}

export default tampereenSahkolaitos
